html {
  box-sizing: border-box;
  -webkit-text-size-adjust: 100%;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  height: 100%;
}

body {
  width: 100%;
  margin: 0;
  overflow-x: hidden !important;
  color: #000;
  font-family: $FFamily, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include text-medium;

  @media (max-width: 768px) {
    @include text-normal;
  }

  &.interface {
    width: 100vw;
    min-width: 1484px;
    height: 100vh;
    overflow: hidden;
  }

  &.noscroll {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
li {
  margin: 0;
  padding: 0;
}

button,
input,
[role="button"],
textarea {
  font-family: inherit;
  background-color: rgba(0, 0, 0, 0);
  border: unset;

  &:focus {
    outline: unset;
  }
}

/* Remove inner shadow from inputs on mobile iOS */
textarea,
input[type="text"] {
  -webkit-appearance: none;
}

input,
textarea {
  font-weight: normal;
  font-size: $FSizeTN;
  font-family: inherit;
  line-height: $FLHTN;
  letter-spacing: $FSpacingN;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $CGrey-100 inset !important;
}

input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.form-field-wrap.--focused {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px $CWhite inset !important;
  }
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

[role="button"],
button,
a {
  text-decoration: unset;
  cursor: pointer;
}

a {
  &:focus,
  &:visited,
  &:active {
    text-decoration: unset;
  }
}

img,
svg {
  display: block;
  flex-shrink: 0;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: $CGrey-500;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: $CGrey-500;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: $CGrey-500;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $CGrey-500;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $CGrey-500;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: $CGrey-500;
}

.--error {
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $CRed;
    opacity: 1;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $CRed;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: $CRed;
  }

  &::placeholder {
    /* Most modern browsers support this now. */
    color: $CRed;
  }
}

[type='search'] {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

textarea {
  width: 100%;
  min-height: 180px;
  border: none;
  resize: none !important;
}

ul,
ol {
  padding-left: 15px;

  &.--num {
    margin-left: 50px;
    list-style: decimal;

    li {
      padding: 10px 0;
    }
  }

  &:not(.--simple) {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.text-elipsed {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.modal-dialog {
  &.no-visible {
    .modal-content {
      width: min-content;
      margin: 0 auto;
      background-color: transparent;
      border: unset;
    }
  }
}

.Toastify {
  position: absolute;
  z-index: 9999999;
}

.dec-under {
  position: relative;
  display: inline-block;
  padding-bottom: 0;
  color: currentColor;
  font-style: normal;

  &::before {
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: currentColor;
    content: "";
  }

  &.--o::before {
    background-color: $COrange;
  }

  &.--p::before {
    background-color: $CPurple;
  }

  &.--g::before {
    background-color: $CGreen;
  }

  &.--s::before {
    height: 1px;
  }
}

#root {
  width: 100%;
  overflow-x: hidden;
}

table {
  border: 1px solid black;
  border-collapse: collapse;

  td,
  th {
    padding: 10px;
    border: 1px solid black;
  }
}

.relative {
  position: relative;
}
