@keyframes scale {
  0%,
  100% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }
}

@keyframes slideFromCenter {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes slideFromLeft {
  0% {
    transform: translateX(-30px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromRight {
  0% {
    transform: translateX(30px);
    opacity: 0;
  }

  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideFromBottom {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideFromTop {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.a-init-ffr {
  transform: translateX(-30px);
  opacity: 0;
}

.a-ffr {
  animation: slideFromRight 0.7s ease-in-out forwards !important;
}

.a-init-ffl {
  transform: translateX(30px);
  opacity: 0;
}

.a-ffl {
  animation: slideFromLeft 0.7s ease-in-out forwards !important;
}

.a-init-ffb {
  transform: translateY(30px);
  opacity: 0;
}

.a-ffb {
  animation: slideFromBottom 0.7s ease-in-out forwards !important;
}

.a-init-fft {
  transform: translateY(-30px);
  opacity: 0;
}

.a-fft {
  animation: slideFromTop 0.7s ease-in-out forwards !important;
}

.a-init-delivery,
.a-init-ads {
  & > img,
  .img-icon > * {
    position: relative;
    opacity: 0;
  }
}

.a-adscr {
  & > img {
    animation: slideFromRight 0.7s ease-in-out forwards !important;
  }

  .--c-icon > * {
    animation: slideFromBottom 1s 0.5s ease-in-out forwards !important;
  }
}

.a-adscl {
  & > img {
    animation: slideFromLeft 0.7s ease-in-out forwards !important;
  }

  .--c-icon > * {
    animation: slideFromBottom 1s 0.5s ease-in-out forwards !important;
  }
}

.a-adslk {
  .--adslk-i-1 > * {
    animation: slideFromTop 1s 0.3s ease-in-out forwards !important;
  }

  .--adslk-i-2 > * {
    animation: slideFromBottom 1s 0.6s ease-in-out forwards !important;
  }

  .--adslk-i-3 > * {
    animation: slideFromTop 1s 0.9s ease-in-out forwards !important;
  }
}

.a-ads4 {
  & > img {
    animation: slideFromRight 0.7s ease-in-out forwards !important;
  }

  .--ads4-i-apay > * {
    animation: slideFromTop 1s ease-in-out forwards !important;
  }

  .--ads4-i-card > * {
    animation: slideFromRight 1s ease-in-out forwards !important;
  }

  .--ads4-i-wallet > * {
    animation: slideFromBottom 1s ease-in-out forwards !important;
  }

  .--ads4-i-gpay > * {
    animation: slideFromBottom 1s ease-in-out forwards !important;
  }
}

.img-delivery {
  width: 565px !important;
  max-width: unset !important;
  height: 520px !important;

  @media (max-width: 768px) {
    width: 343px !important;
    height: 315px !important;

    & > * {
      position: relative;
      transform: scale(0.7);
    }
  }
}

.a-delivery {
  @media (max-width: 768px) {
    & > * {
      transform: translate(-50%, -50%) scale(0.6) !important;
    }
  }

  .--del-i-1 {
    top: 3%;
    right: 20%;

    @media (max-width: 768px) {
      top: 17%;
      right: -15%;
    }

    svg {
      animation: slideFromTop 1s ease-in-out 0.2s forwards !important;
    }
  }

  .--del-i-2 {
    top: 26%;
    right: -3%;

    @media (max-width: 768px) {
      top: 46%;
      right: -52%;
    }

    svg {
      animation: slideFromRight 1s ease-in-out 0.2s forwards !important;
    }
  }

  .--del-i-3 {
    right: 18%;
    bottom: 9%;

    @media (max-width: 768px) {
      right: -3%;
      bottom: -13%;
    }

    svg {
      animation: slideFromBottom 1s 0.2s ease-in-out forwards !important;
    }
  }

  .--del-i-4 {
    bottom: 5%;
    left: 23%;

    @media (max-width: 768px) {
      bottom: -30%;
      left: 35%;
    }

    svg {
      animation: slideFromBottom 1s 0.2s ease-in-out forwards !important;
    }
  }

  .--del-i-5 {
    top: 35%;
    left: 0%;

    @media (max-width: 768px) {
      top: 52%;
      left: 13%;
    }

    svg {
      animation: slideFromLeft 1s 0.2s ease-in-out forwards !important;
    }
  }

  .--del-i-6 {
    top: 7%;
    left: 18%;

    @media (max-width: 768px) {
      top: 21%;
      left: 30%;
    }

    svg {
      animation: slideFromTop 1s 0.2s ease-in-out forwards !important;
    }
  }

  .--del-i-7 {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
      animation: slideFromCenter 0.5s ease-in-out forwards !important;
    }
  }
}

@keyframes radial-pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(30, 205, 101, 0.5);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(30, 205, 101, 0);
  }
}

.el-blink {
  animation: radial-pulse 2s infinite;
}
