$size-0: 0;
$size-min: 4px;
$size-1: 8px;
$size-2: 12px;
$size-3: 16px;
$size-4: 24px;
$size-5: 32px;
$size-6: 40px;
$size-7: 48px;
$size-8: 65px;
$size-10: 10px;
$size-20: 20px;
$size-30: 30px;
$size-40: 40px;
$size-50: 50px;
$size-60: 60px;
$size-100: 100px;

$sizes: (
  0: $size-0,
  min: $size-min,
  1: $size-1,
  2: $size-2,
  3: $size-3,
  4: $size-4,
  5: $size-5,
  6: $size-6,
  7: $size-7,
  8: $size-8,
  10: $size-10,
  20: $size-20,
  30: $size-30,
  40: $size-40,
  50: $size-50,
  60: $size-60,
  100: $size-100,
  a: auto,
);

$variants: (
  t: top,
  b: bottom,
  l: left,
  r: right,
);

$targets: (
  m: margin,
  p: padding,
);

@each $targetIndex, $targetValue in $targets {
  @each $sizeIndex, $sizeValue in $sizes {
    @each $variantIndex, $variantValue in $variants {
      .#{$targetIndex}#{$variantIndex}-#{$sizeIndex} {
        #{$targetValue}-#{$variantValue}: $sizeValue !important;
      }
    }

    .#{$targetIndex}a-#{$sizeIndex} {
      #{$targetValue}: $sizeValue !important;
    }

    .#{$targetIndex}x-#{$sizeIndex} {
      #{$targetValue}-left: $sizeValue !important;
      #{$targetValue}-right: $sizeValue !important;
    }

    .#{$targetIndex}y-#{$sizeIndex} {
      #{$targetValue}-top: $sizeValue !important;
      #{$targetValue}-bottom: $sizeValue !important;
    }
  }
}
