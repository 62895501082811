$scrollbarThumbColor: $CGrey-300;
$scrollbarThumbHoverColor: $CGrey-500;
$scrollbarThumbActiveColor: $CGrey-500;
$scrollbarTrackColor: $CTransparent;

.app * {
  @media (min-width: 769px) {
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-button {
      width: 0;
      height: 0;
    }

    &::-webkit-scrollbar-corner {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: $scrollbarThumbColor;
      border-radius: 4px;

      &:hover {
        background: $scrollbarThumbHoverColor;
      }

      &:active {
        background: $scrollbarThumbActiveColor;
      }
    }

    &::-webkit-scrollbar-track {
      background: $scrollbarTrackColor;
    }
  }
}
