@import '../variables.scss';

.custom-datepicker {
  width: 100%;
  min-width: 346px;

  .react-datepicker__day--in-selecting-range:not(.react-datepicker__day--outside-month) + .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range:not(.react-datepicker__day--outside-month) + .react-datepicker__day--in-range {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      right: 100%;
      bottom: 0;
      width: 18px;
      margin-right: -7px;
      background-color: #1890d6;
      content: '';
    }
  }

  .react-datepicker {
    width: 100%;
    font-family: inherit;
    background: rgba(255, 255, 255, 0.9);
    border: unset;
    // border: 0.5px solid #e2e8f0;
    border-radius: 10px;
    box-shadow: 4px 10px 25px 0 rgba(113, 128, 150, 0.12), 0 0 0 0.5px #e2e8f0 inset;
    backdrop-filter: blur(50px);

    &__month {
      margin-top: 0;
      margin-bottom: 10px;

      &-container {
        width: 100%;
      }
    }

    &__header {
      padding-top: 4px;
      background-color: #fff;
      border-bottom-color: #fff;

      .arrow {
        padding: 0;
        color: $CGrey-500;
        transition: color 0.3s ease-in-out;

        svg {
          fill: currentColor;
        }
      }
    }

    &__current-month {
      margin-bottom: 25px;
    }

    &__day-name,
    &__day {
      width: 100%;
      max-width: 36px;
      margin: 0 5px;
      font-family: inherit;
      letter-spacing: -0.2px;
    }

    &__day-name {
      height: 32px;
      color: $CGrey-500;
      font-size: 14px;
      line-height: 32px;
      text-transform: capitalize;
    }

    &__day {
      height: 36px;
      margin-top: 5.5px;
      margin-bottom: 5.5px;
      font-size: 16px;
      line-height: 38px;
      border-radius: 10px;
      transition: background-color 0.3s ease-in-out;

      &:hover,
      &--today:hover {
        background-color: rgba($CGreen, 0.2);
      }

      &:focus {
        outline: none;
      }

      &--in-selecting-range,
      &--in-range {
        background-color: #1890d6 !important;
      }

      &--outside-month {
        color: $CGrey-500;
      }

      &--disabled {
        pointer-events: none;
      }

      &--selected {
        color: $CWhite;
        font-weight: $FWM;
        background-color: $CGreen !important;
      }

      &--today {
        font-weight: normal;
        background-color: $CGrey-200;
      }

      &--highlighted {
        &-day-acsent {
          color: #1890d6;
          font-weight: bold;
          background-color: #e8f4fb;
        }

        &-day-pink {
          color: #8430cb;
          font-weight: bold;
          background-color: #f9f2ff;
        }

        &-current {
          color: #fff;
          font-weight: bold;
          background-color: #32c5d5;
        }

        &-record {
          position: relative;
        }
      }
    }
  }
}
